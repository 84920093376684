import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./template/layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./template/layouts/content/content-layout.component";
import { AuthGuard } from './_helpers/auth.guard';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './shopping-cart/checkout/checkout.component';

// import { Full_ROUTES } from "./template/shared/routes/full-layout.routes";
// import { CONTENT_ROUTES } from "./template/shared/routes/content-layout.routes";
// import { VROUTES } from './template/shared/vertical-menu/vertical-menu-routes.config';
// import { HROUTES } from './template/shared/horizontal-menu/navigation-routes.config';

const appRoutes: Routes = [
	{
		path: '',
		canActivate:[AuthGuard],
		component: FullLayoutComponent,
		children: [
			{
				path: 'shopping-cart',
				component: ShoppingCartComponent
			},
			{
				path: 'checkout',
				component: CheckoutComponent
			},
			{
				path: 'home',
				loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
			},
			{
				path: 'profile',
				loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
			},
			{
				path: 'new_booking',
				loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
			},
			{
				path: 'bookings',
				loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
			},
			{
				path: 'orders',
				loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
			},
			{
				path: 'address_book',
				loadChildren: () => import('./address-book/address-book.module').then(m => m.AddressBookModule)
			},
			{
				path: 'products',
				loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
			},
		]
	},
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: '', component: ContentLayoutComponent,
		data: { title: 'content Views' },
		children: [{
		path: '',
			loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule)
		}]
	},
	{
		path: '**',
		redirectTo: 'error'
	}

//   {
//     path: '',
//     redirectTo: 'pages/login',
//     pathMatch: 'full',
//   },
//   { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
//   { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
//   {
//     path: '**',
//     redirectTo: 'pages/error'
//   }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
