<section class="page-user-profile">
	<div class="row">
		<div class="col-12">
			<div class="card">
        		<div class="card-header d-flex justify-content-between align-items-center">
					<h4 class="card-title m-0">{{ 'SHOPPING_CART'|translate }}</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						
                        <div class="row">
                            <div class="col-12 col-lg-7 col-md-6 ">
                                <span class="text-large d-inline-block font-weight-500 text-bold-600 mb-3">Billing details</span>
                                <form [formGroup]="regForm" id="regForm">
                                    <div class="row">
                                        <div class="col-md-6 mb-2">
                                            <label >First name <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" formControlName="firstname">
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label>Last name <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" formControlName="lastname" required>
                                        </div>    
                                        <div class="col-12 mb-2">
                                            <label>Company name (optional)</label>
                                            <input class="form-control" formControlName="company_name" type="text">
                                        </div>
                                        <div class="col-12 mb-2">
                                            <label>Street address <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" formControlName="address_1" placeholder="House number and street name">
                                            <input class="form-control" type="text" formControlName="address_2" placeholder="Apartment,suite,unit etc. (optional)">
                                        </div>
                                        <div class="col-12 mb-2">
                                            <label>Suburb/State/Postal Code<span class="text-danger">*</span></label>
                                            <ng-select formControlName="address" [minTermLength]="postalCodeMinLengthTerm" [loading]="postalCodeLoading"
                                                [typeahead]="postalCodeInput$" >
                                                <ng-option *ngFor="let item of postalCodes$ | async" [value]="item">
                                                    {{ item.suburb + ', ' + item.state + ', ' + item.postcode }}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-12 mb-2">
                                            <label>Phone <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" formControlName="contact_no" required>
                                        </div>
                                        <div class="col-12 mb-2">
                                            <label>Email address <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" formControlName="email" required>
                                        </div>
                                        <div class="col-12">
                                            <label>Reference No (optional)</label>
                                            <input class="form-control" type="text" formControlName="reference_no">
                                        </div>
                                        <div class="col-12 mt-2">
                                            <label>Order notes (optional)</label>
                                            <textarea class="form-control" rows="5" cols="5" formControlName="order_note" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-12 col-lg-5 col-md-6">
                                <div class="p-2">
                                    <span class="text-large d-inline-block font-weight-500 text-bold-600 mb-3">Your order</span>
                                    <table class="table mb-3 table-sm table-hover">
                                        <thead class="border-bottom">
                                            <tr>
                                                <th class="product-name font-weight-500 w-60">Product</th>
                                                <th class="product-total pe-0 font-weight-500">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of cartService.cart.orderList; let i = index">
                                                <td>{{product.name}}
                                                    <span class="d-block w-100">Quantity: {{product.qty}}</span>
                                                    <span class="d-block w-100">Unit Price: ${{product.unit_price}}</span>
                                                </td>
                                                <td>${{product.total()}}</td>
                                            </tr>
                                            <tr class="shipping">
                                                <th class="font-weight-500 text-extra-dark-gray">Shipping</th>
                                                <td>
                                                    <ul class="">
                                                        <li class="d-flex align-items-center">
                                                            <input id="free_shipping" type="radio" name="shipping-option" class="d-block w-auto mb-0 form-control mr-2" checked="checked">
                                                            <label class="md-line-height-18px" for="free_shipping">Free shipping</label>
                                                        </li>
                                                    </ul>
                                                    <p class="text-start text-small mb-0"></p>
                                                </td>
                                            </tr>
                                            <tr class="total-amount">
                                                <th class="font-weight-500 text-mute">Total</th>
                                                <td>
                                                    <h6 class="d-block font-weight-500 mb-0">${{cartService.cart.total}}</h6>
                                                    <span class="text-small"></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p class="font-small-3">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a class="text-decoration-underline" href="#">privacy policy.</a></p>
                                    <p class="d-flex align-items-center">
                                        <input class="d-inline w-auto mb-0 mx-2" type="checkbox" name="terms-and-condition" id="termsAgreementFlag" [checked]="termsAgreementFlag" (change)="termsAgreementFlag = !termsAgreementFlag">
                                        <span class="font-small-3">I have read and agree to the website <a class="text-decoration-underline" href="#">terms and conditions</a><span class="text-red ms-1">*</span></span>
                                    </p>
                                    <button type="submit" [disabled]="!termsAgreementFlag" (click)="placeOrder()" class="btn btn-block w-100 mt-2 btn-primary">Place order</button>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>