import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShoppingCartService } from './shopping-cart.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
	dataLoadingFlag = true;
	environment = environment;
	
	constructor(private cdref: ChangeDetectorRef, 
		private spinner: NgxSpinnerService,
		private translate: TranslateService,
		public cartService: ShoppingCartService,
		private toastr: ToastrService,) { }

	ngOnInit(): void {
	}

	// closeModal() {
    //     this.activeModal.close();
    // }

}
