import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart } from 'app/_models/Cart';
import { Product } from 'app/_models/Product';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class ShoppingCartService {
    public cart : Cart;

    constructor(private http: HttpClient,private toastr: ToastrService) {
        this.cart = new Cart;
        this.cart.restore();
    }

    addToCart(product: Product) {
		const msg = product.name + ' - Added to Cart';
		const header = '';
		this.toastr.success(header, msg, {
			positionClass: 'toast-top-right',
		});
		this.cart.addToCart(product);
	}
}
