
import {Product} from './Product';
export class Cart
{
    public orderList:Array<Product>=[];
    public customer:any={};
    public total:string="00.00";
    addToCart(product:Product){
        product.is_in_cart = true;
        if(product.is_in_cart){
            this.removeProductById(product.id);
        }
        this.orderList.push(product);
        this.refresh();
    }
    removeProductById(product_id:any):void{
        this.orderList = this.orderList.filter((product:Product)=>{
            return product.id != product_id;
        });
        this.refresh();
    }

    private calcTotal():number{
        let total = 0;
        for(let product of this.orderList){
            total+= product.total();
        }
        return total;
    }

    toJson():string{
        return JSON.stringify(this);
    }

    saveToStorage(){
        localStorage.setItem('cart',this.toJson());
    }
    
    restore(){
        const savedCart = localStorage.getItem('cart');
        if(savedCart){
            const cart = JSON.parse(savedCart);
            this.orderList=[];
            for(let lineItem of cart.orderList){
                const product = Product.fromApi(lineItem);
                this.addToCart(product);
            }
            this.customer = cart.customer;
        }
    }

    empty():void{
        this.orderList=[];
        this.customer = {};
        this.refresh();
    }

    refresh():void{
        this.total = this.calcTotal().toFixed(2);
        this.saveToStorage();
    }

    addOne(product:Product){
        product.setQty(product.qty+1);
        console.log('add one',product);
        this.addToCart(product);
    }
    subOne(product:Product){
        product.setQty(product.qty-1);
        if(product.qty == 0){
            this.removeProductById(product.id);
            return;
        }
        this.addToCart(product);
    }
}
