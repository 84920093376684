import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BaseService {
    postCodes = null;
    settings = null;

    constructor(private http: HttpClient,) { }

    public getPostCodeList(params, cache = true): Observable<any> {
        if (!this.postCodes || !cache) {
            return this.http.get(environment.apiUrl + "/api/postcodes", {params}).pipe(
                map((data: any) => {
                    console.log('getPostCodeList', data);
                    return this.postCodes = data;
                })
            );
        } else {
            return of(this.postCodes);
        }
    }

    public getSettings(): Observable<any> {
        if (!this.settings) {
            return this.http.get(environment.apiUrl + "/api/settings").pipe(
                map((data: any) => {
                    console.log('settings', data);
                    return this.settings = data;
                })
            );
        } else {
            return of(this.settings);
        }
    }

}
