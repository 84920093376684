import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BaseService } from 'app/_services/base.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, concat, of, throwError } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
	environment = environment;

	regForm: FormGroup = this.fb.group({
		company_name: ['', Validators.required],
		firstname: ['', Validators.required],
		lastname: ['', Validators.required],
		email: ['', Validators.required],
		contact_no: ['', Validators.required],
		address_1: ['', Validators.required],
		address_2: [''],

		address: [null, Validators.required],
		// suburb: ['', Validators.required],
		// state: ['', Validators.required],
		// postcode: ['', Validators.required],

		reference_no: '',
		order_note: '',

		// username: ['', Validators.required],
		// pass: ['', Validators.required],
		// confirm_pass: ['', Validators.required],
	});


	postalCodeMinLengthTerm = 1;
	postalCodes$: Observable<any> = new Observable<any>();
	postalCodeLoading = false;
	postalCodeInput$ = new Subject<any>();

	termsAgreementFlag = false;

	constructor(public cartService: ShoppingCartService,
		private fb: FormBuilder,
		private baseService: BaseService,
		private toastr: ToastrService,) { }

	ngOnInit(): void {
		// this.cartService.getAllCartItems().subscribe(res=>{
		// 	this.shoppingCart = res;
		// 	this.calcCartTotal();
		// 	console.log(this.shoppingCart)
		// });

		this.loadPostalCodes();
	}

	// calcCartTotal() {
	// 	this.grandTotal = this.cartService.getTotalPrice();
	// }

	loadPostalCodes() {
		this.postalCodes$ = concat(
			of([]), // default items
			this.postalCodeInput$.pipe(
				filter(res => {
					return res !== null && res.length >= this.postalCodeMinLengthTerm
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.postalCodeLoading = true),
				switchMap(term => {
					console.log(term)
					return this.getPostCodes(term).pipe(
						catchError(() => of([])), // empty list on error
						tap(() => this.postalCodeLoading = false)
					)
				})
			)
		);
	}

	loadToPostalCodes() {
		this.postalCodes$ = concat(
			of([]), // default items
			this.postalCodeInput$.pipe(
				filter(res => {
					return res !== null && res.length >= this.postalCodeMinLengthTerm
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.postalCodeLoading = true),
				switchMap(term => {
					console.log(term)
					return this.getPostCodes(term).pipe(
						catchError(() => of([])), // empty list on error
						tap(() => this.postalCodeLoading = false)
					)
				})
			)
		);
	}

	getPostCodes(term: any) {
		const params = {
			q: term,
		}
		return this.baseService.getPostCodeList(params, false).pipe(map(resp => {
			if (resp.Error) {
				throwError(resp.Error);
			} else {
				return resp;
			}
		}));
	}

	findInvalidControls(form: any) {
		const invalid = [];
		const controls = form.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		console.log(invalid);
		return invalid;
	}

	placeOrder() {
		console.log("checkout clicked")
		console.log(this.regForm.value)
		if (this.regForm.invalid) {
			this.findInvalidControls(this.regForm)
			const msg = 'Invalid Form';
			const header = 'Please fill the required fields...';
			this.toastr.error(header, msg, {
				positionClass: 'toast-top-right',
			});
			
			document.getElementById('regForm')?.classList.add('input-error');
		} else {

			if(!this.termsAgreementFlag) {
				const msg = 'Invalid';
				const header = 'Please agree with terms and conditions...';
				this.toastr.warning(header, msg, {
					positionClass: 'toast-top-right',
				});
				
			}
			const customerData = this.regForm.value;

			const address = this.regForm.get("address")?.value;
			customerData.suburb = address.suburb;
			customerData.postcode = address.postcode;
			customerData.state = address.state;

			const data = {
				"order_list": this.cartService.cart.orderList,
				"customer": customerData,
				"reference_no": this.regForm.get('reference_no')?.value,
				"order_note": this.regForm.get('order_note')?.value,
			}

			console.log("Final Data", data);
		}
	}
}
