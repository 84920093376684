<section class="page-user-profile">
	<div class="row">
		<div class="col-12">
			<div class="card">
        		<div class="card-header d-flex justify-content-between align-items-center">
					<h4 class="card-title m-0">{{ 'SHOPPING_CART'|translate }}</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<div class="col-12 text-center">
							<span *ngIf="dataLoadingFlag" class="spinner-border spinner-border-sm mr-1"></span>
						</div>
						<div class="table-responsive">
							<table class="table text-center m-0">
								<thead>
									<tr>
									<th>Image</th>
									<th>Product</th>
									<th>Weight</th>
									<th>Price</th>
									<th>Quantity</th>
									<th>Amount</th>
									<th>Delete</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let product of cartService.cart.orderList; let i = index">
										<td><img [src]="environment.apiUrl + product.picture" alt="{{product.name}}" style="height: 75px;"></td>
										<td>{{product.name}}</td>
										<td>38.9 Ounce</td>
										<td>${{product.unit_price}}</td>
										<td>
											<div class="btn-group ml-1">
											<a class="btn btn-primary px-1 py-1 font-medium-5" href="javascript:void(0)" min="0" (click)="cartService.cart.subOne(product)">-</a>
											<a class="btn btn-primary px-1 py-1" href="javascript:void(0)" >{{product.qty}}</a>
											<a class="btn btn-primary px-1 py-1 font-medium-5" href="javascript:void(0)" min="1000" (click)="cartService.cart.addOne(product)">+</a>
											</div>
										</td>
										<td>${{product.total()}}</td>
										<td>
											<a href="javascript:;" class="danger" (click)="cartService.cart.removeProductById(product.id)">
												<i class="ft-trash font-medium-3"></i>
											</a>
										</td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td colspan="2"><b>Sub Total</b></td>
										<td><b>${{cartService.cart.total}}‬</b></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td colspan="2"><b>Total</b></td>
										<td><b>${{cartService.cart.total}}‬</b></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><button class="btn btn-primary" [routerLink]="['/checkout']">Proceed to checkout</button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</section>
