export class Product {
	public is_in_cart:boolean = false;
	public qty:number = 1

	constructor(public id:string="",
			public name:string="",
			public category:string="",
			public picture:string="",
			public remaining_stock:number=0,
			public unit:string="",
			public unit_price:number=0,) { }
	
	public fromApi(product:any){
		this.id = product.id;
		this.name = product.name;
		this.category = product.category;
		this.picture = product.picture;
		this.remaining_stock = product.remaining_stock;
		this.unit = product.unit;
		this.unit_price = product.unit_price;
	}

	public total():number {
		return this.qty * this.unit_price;
	}
	
	public setQty(qty:number) {
		this.qty = qty;
	}

	public static fromApi(product:any):Product{
		const p = new Product(product.id,product.name,product.category,product.picture,product.remaining_stock,product.unit,product.unit_price);
		if(product.hasOwnProperty('qty')){
			p.setQty(product.qty);
		}
		return p;
	}
}